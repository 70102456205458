import './App.css';
import { MapComponent } from './MapComponent';

function App() {
  
  return (
    <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <MapComponent />
    </div>
  );

}

export default App;
